import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import "./style.css";
import { useTranslation } from 'react-i18next';
import { dispatchErrorToast } from "../../utils/functions";

const AkedoGames = () => {
  useBackButton("/boost-rewards", "/home");
  const dispatch = useDispatch();

  const {i18n} = useTranslation();

  useEffect(() => {
    preventCloseWithSwipe();
    dispatch(getPopupMessageAction());

    // Set up the message event listener
    const handleMessage = (event) => {      
      // Check the origin of the message to ensure it's from a trusted source
      if (!event.origin.endsWith('akedo.gg')) {
        console.log('event.origin:', event.origin);
        return;
      }     
      
      // Handle specific message events
      if (event.data.type === 'akedo' && event.data.payload === 'assad_arcade_loaded') {
        dispatch(getPopupMessageAction());
      }
      if (event.data.type === 'akedo' && event.data.payload === 'assad_player_error') {
        dispatchErrorToast(dispatch);
      }
    };

    window.addEventListener("message", handleMessage);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const auth_token = sessionStorage.getItem('token');
  const baseUrl = `${import.meta.env.VITE_BASE_ARCADE_URL}`;
  const direct_link = `${baseUrl}category-custom/19000192/${auth_token}/${i18n.language}`;
  
  return (
    <div className="overflow-y-auto bg-[#161A1C] h-[calc(100vh_-_100px)] pb-0">
		<div className="GamesPage h-full text-white overflow-auto   coustom-scroll">
				<iframe width="100%" height="100%" src={direct_link} title="Akedo"></iframe> 
		</div>
	</div> 
  )
}

export default AkedoGames

import { useEffect,useRef,useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import LionUserImage from '../../assets/images/friends-lion-icon.svg';
import DollarImage from "../../assets/images/dollar.png";
import gift from "../../assets/images/giftSmall.png";
import giftShadow from "../../assets/images/giftShadow.png";
import friends_blck_Icon from "../../assets/images/friends_blck_icon.svg";
import buttonFriendsBg from "../../assets/images/buttonFriends.png";
import copyIcon from "../../assets/images/copyIcon.svg";
import copyBg from "../../assets/images/copyBg.png";

import { checkFriendsAction, claimRewardActions } from '../../redux/actions/friendsActions';
import { AddCoinAction, AddToLocalCoinsAction } from '../../redux/actions/gameActions';
import { UpdateBalanceAfterClaimAction } from '../../redux/actions/authActions';
import { UserPointsKey } from '../../general.js';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { useCoins } from '../../hooks/useCoins.js';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import { FriendsReferCopyClickAction, FriendsReferShareClickAction, GameFriendsInviteClickAction } from '../../redux/actions/googleAnalyticsActions.js';



const Friends =()=>{
	useBackButton("/friends", "/home");

	const FriendsState = useSelector((state) => state.Friends);
	const { t  } = useTranslation();
	const AuthState = useSelector((state) => state.Auth);
	const [inviteLoading,setInviteLoading] = useState(false);
	const [tooltipText, setTooltipText] = useState("");
	const [showTooltip, setShowTooltip] = useState(false);
	const textToCopyRef = useRef(null);
	const dispatch = useDispatch();
 	const { friendsList:friendsListStateArr } = FriendsState;
	const { user } = AuthState;
	const { currentCoinsRef} = useCoins();
	let __link = import.meta.env.VITE_APP_ENVIROMENT === 'prod'  ? `https://t.me/share/url?url=https://t.me/TheAssadGame_bot?start=${user?.id}` : `https://t.me/share/url?url=https://t.me/Celery_Cosmos_bot?start=${user?.id}`
	let __link_start = 	 import.meta.env.VITE_APP_ENVIROMENT === 'prod'  ? `https://t.me/TheAssadGame_bot?start=${user?.id}` : `https://t.me/Celery_Cosmos_bot?start=${user?.id}` 

	const copyToClipboard = () => {
		const inputElement = textToCopyRef.current;

		inputElement.value = __link_start;
		inputElement.select();
		document.execCommand("copy");
		
		dispatch(FriendsReferCopyClickAction())
		setTooltipText('friends.invite_link_copied');
		setShowTooltip(true);
	
		setTimeout(() => {
			setTooltipText('');
		  	setShowTooltip(false);
		}, 1500);
	};
	
	

  useEffect(()=>{
		preventCloseWithSwipe();
		dispatch(getPopupMessageAction());
		dispatch(checkFriendsAction(claimRewardOnCheckFriends));
  },[]);

	const claimRewardOnCheckFriends = async()=>{
		setInviteLoading(true);
		//dispatch(claimRewardActions({user_id:user?.id,ref_type:'friend'},onClaimRewardSuccess,onClaimRewardFailure));
	}

	const onClaimRewardSuccess = (multiplyWith) => {
		if (parseInt(multiplyWith.friends) > 0){
			const rewardCoins = parseInt(multiplyWith.friends) * parseInt(multiplyWith.coins);
			const newUserCoins = currentCoinsRef.current + rewardCoins;
			dispatch(AddToLocalCoinsAction(rewardCoins));
			dispatch(AddCoinAction({ amount: rewardCoins, coinType: 'click' }));
			localStorage.setItem(UserPointsKey, newUserCoins);
			const newUserObject = {
				...user,
				balance: newUserCoins
			};
			dispatch(UpdateBalanceAfterClaimAction(newUserObject));
			setInviteLoading(false);			
		}
	}

	const onClaimRewardFailure =()=>{
		setInviteLoading(false);
	}

  return (
		<div className="friendsPage h-full text-white overflow-auto pb-[70px] coustom-scroll">
			{/* <Header /> */}
			<div className="wrapperScroll">
				<div className="px-[10px]">
					<div className="text-center">
						<h1 className="text-[30px] mt-4 font-semibold">
							{t('friends.title')}
						</h1>
						<p className="text-[16px] text-[#a5a5a5] mt-2.5 font-medium">
							{t('friends.description')}
						</p>
					</div>
					<div className="m-4 mx-2">
						<div className="border-solid border-[5px] border-[#454849] relative p-[2px] bg-gradient-to-r from-[#FCDF4C] via-[#A06203] to-[#FCDF4C] rounded-3xl" style={{ backgroundImage: "linear-gradient(90deg, #FCDF4C -0.13%, #F1D043 2.15%, #D3A72B 9.27%, #BB8718 16.25%, #AA700B 22.95%, #A06203 29.23%, #9C5D00 34.67%, #A16304 38.08%, #AE760E 42.84%, #C49420 48.42%, #E3BD38 54.54%, #FCDF4C 58.94%, #FDE789 66.48%, #FFEEB8 72.99%, #FFF0CA 76.24%, #FFF0C7 78.85%, #FFEEBD 80.85%, #FEECAD 82.63%, #FEE996 84.3%, #FDE578 85.88%, #FCE054 87.39%, #FCDF4C 87.69%, #CC9E26 93.3%, #A96F0A 97.85%, #9C5D00 100.13%)" }}>
							<div className="bg-gradient-to-b from-[#454849] to-[#2d3032] rounded-2xl  p-2 overflow-hidden bg-white">
								<div key={`friends_list_1`} className="flex gap-2 items-center mb-2">
									<span className='relative inline-block'>
										<img src={gift} className="relative z-10 w-[70px] aspect-square" alt="option.icon" />
										<img src={giftShadow} className="w-28 aspect-square mix-blend-screen absolute bottom-2 left-0" alt="option.icon" />
									</span>
									<div className="flex flex-col gap-1 flex-1">
										<h3 className="font-semibold text-md"> {t('friends.invite_friends')}  </h3>
										<span className="text-[12px] text-white flex flex-wrap gap-[5px] items-center font-medium">
											<img src={DollarImage} alt="DollerIamge" className="w-4 aspect-square" />
											<span className="text-[#F3B806]">{`+5,000`}</span>
											{t('friends.for_you_and_your_friend')}
										</span>
									</div>
								</div>
								<div key={`friends_list_2`} className="flex gap-2 items-center" >
									{/* <span>
										<img src={gift} className="min-h-20 min-w-20 max-h-20 max-w-20" alt="option.icon" />
									</span> */}
									<span className='relative inline-block'>
										<img src={gift} className="relative z-10 w-[70px] aspect-square" alt="option.icon" />
										<img src={giftShadow} className="w-28 aspect-square mix-blend-screen absolute bottom-2 left-0" alt="option.icon" />
									</span>
									<div className="flex flex-col gap-1 flex-1">
										<h3 className="font-semibold text-md"> {t('friends.invite_telegram_premium')} </h3>
										<span className="text-[12px] text-white flex flex-wrap gap-[5px] items-center font-medium">
											<img src={DollarImage} alt="DollerIamge" className="w-4 aspect-square" />
											<span className="text-[#F3B806]">{`+25,000`}</span>
											{t('friends.for_you_and_your_friend')}
										</span>
									</div>
								</div>

								<div className="flex md:gap-5 gap-[15px] items-center mt-[10px] px-[10px]">
									<button className="bg-full flex my-1 items-center justify-center md:gap-4 gap-1 font-semibold  rounded-xl w-full text-[#2F2F2F] p-[5px] md:p-[20px] max-w-[350px] md:h-auto h-[45px]  bg-no-repeat"
											style={{ backgroundImage: `url(${buttonFriendsBg})`}}
											onClick={() => {
												dispatch(GameFriendsInviteClickAction())
												dispatch(FriendsReferShareClickAction())
												window.Telegram.WebApp.openTelegramLink(__link);
											}}
									>
										<img src={friends_blck_Icon} alt='' className=" md:w-[40px] md:h-[40px] w-[30px] h-[30px] " />
										<h2 className="md:text-[19px] text-[15px]">{t('friends.invite_button')}</h2>
									</button>
									<input
										ref={textToCopyRef}
										type="text"
										style={{position: "absolute",opacity: 0, pointerEvents: "none",
											userSelect: 'none',
											WebkitUserSelect: 'none',MozUserSelect: 'none',msUserSelect: 'none'
										}} 										
										readOnly
									/>
									<button className="relative group flex my-1 items-center justify-center md:gap-4 gap-1 font-semibold  rounded-[19px] text-[#2F2F2F] bg-cover p-[5px] md:p-[20px] md:max-w-[75px] max-w-[55px] w-full md:h-auto h-[45px] bg-[100%] bg-no-repeat bg-full" style={{ backgroundImage: `url(${copyBg})`}} onClick={copyToClipboard}>
										{
											showTooltip && (
												<span className="absolute bottom-full transform mb-2 opacity-100 transition-opacity duration-300 bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-no-wrap pointer-events-none z-10 w-max right-0" id="tooltip-text">
													{t(tooltipText)}
												</span>
											)
										}
										
										<img className=" md:w-[40px] md:h-[40px] w-[30px] h-[30px]"  src={copyIcon} alt='' /> 
									</button>
								</div>

								
							</div>
						</div>
					</div>
					<div className="m-4">
						<div className="flex flex-col my-3 gap-2">
							<span className="text-[13px] text-[#a5a5a5] font-semibold">
								{t('friends.list_your_friends')} ({friendsListStateArr.count})
							</span>
							<div className="flex flex-wrap items-center justify-between gap-2 max-h-[260px] overflow-y-auto pr-2.5">
								{friendsListStateArr.friends &&
								friendsListStateArr.friends.length > 0 ? (
									friendsListStateArr.friends.map((friend, index) => {
										return (
											<div
												key={`invited_friends_${index}`}
												className="flex flex-col justify-center items-center  p-2 bg-[#454849] w-[76px] h-[79px] rounded-[23px]"
											>
												<img
													src={LionUserImage}
													alt="LionUserImage"
													width={"45px"}
													height={"45px"}
												/>
												<h2 className="text-[10px] mt-[5px] text-[#A5A5A5]">
													{friend}
												</h2>
											</div>
										);
									})
								) : (
									<>
										<h2 className="text-[12px] mt-[5px] text-[#A5A5A5]">
											{t('friends.no_friends')}
										</h2>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
  );
}
export default Friends;
